import {action, makeObservable, observable} from 'mobx';
import TableInterface from 'app/interfaces/poker/TableInterface';
import Get from 'app/utils/Get';
import {
    subcribePokerTables,
    subscribePokerTable,
    unsubscribe
} from 'modules/WsSubscription';
import {userStore} from './global';

const TWO_SECONDS = 2000;

export class PokerStore {
    @observable tables: TableInterface[] = [];

    @observable currentTable: TableInterface = null;

    @observable timerId: null | ReturnType<typeof setTimeout> = null;

    @observable subscription = null;

    @observable subcriptionOnTables = null;

    constructor() {
        makeObservable(this);
    }

    @action
    openTable(table_id: string): void {
        this.currentTable = this.tables.find(x => x.table_id === table_id);
        this.clearPokerTimeoutAndRestart();
    }

    @action
    closeTable(): void {
        this.clearPokerTimeout();
        this.currentTable = null;
    }

    @action
    setTables = (tables: TableInterface[]): void => {
        this.tables = tables;
    };

    @action
    updateCurrentTable = (table: TableInterface): void => {
        this.currentTable = table;
    };

    @action
    unsubscribeChannel = (): void => {
        this.subscription = unsubscribe(this.subscription);
    };

    @action
    subscribeChannel = (): void => {
        this.subscription = subscribePokerTable(userStore.user.id, ({data: {event, payload}}) => {
            if (event === 'update') {
                this.updateCurrentTable(payload.table);
            }
        });
    };

    @action
    setTimerId = (timerId: null | ReturnType<typeof setTimeout>): void => {
        this.timerId = timerId;
    };

    setPokerTimeout(): null | ReturnType<typeof setTimeout> {
        return setTimeout(() => this.loadTable(this.currentTable.table_id),
            TWO_SECONDS);
    }

    clearPokerTimeoutAndRestart(): void {
        this.clearPokerTimeout();
        this.setTimerId(this.setPokerTimeout());
    }

    clearPokerTimeout(): void {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }

    listen(): void {
        this.subcriptionOnTables = subcribePokerTables(({data: {event, payload}}) => {
            if (event === 'update') {
                this.setTables(payload.tables);
            }
        });
    }

    loadTable = (table_id: string): void => {
        new Get({
            url: `/poker/tables/${table_id}`
        }).execute()
            .then(response => response.json())
            .then(response => {
                this.updateCurrentTable(response);
                this.setPokerTimeout();
            });
    };

    loadTables = (): void => {
        new Get({
            url: '/poker/tables'
        }).execute()
            .then(response => response.json())
            .then(response => {
                this.setTables(response);
            });
    };
}
