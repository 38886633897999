import React, {ReactNode} from 'react';
import PokerPanelButtonFold from 'app/components/poker/poker_controller_panel/PokerPanelButtonFold';
import PokerPanelButtonCheckCall from 'app/components/poker/poker_controller_panel/PokerPanelButtonCheckCall';
import PokerPanelButtonBetRaise from 'app/components/poker/poker_controller_panel/PokerPanelButtonBetRaise';
import PokerPanelButtonPokerGoBet from 'app/components/poker/poker_controller_panel/PokerPanelButtonPokerGoBet';
import {inject, observer} from 'mobx-react';
import {PokerStore} from 'app/store/PokerStore';
import {PokerController} from 'app/controllers/PokerController';

interface Props {
    pokerStore?: PokerStore
    controller?: PokerController
}

@inject('pokerStore', 'controller')
@observer
export default class PokerControllerPanel extends React.Component<Props> {
    check(): void {
        this.props.controller.check(this.props.pokerStore.currentTable.table_id);
    }

    call(): void {
        this.props.controller.call(this.props.pokerStore.currentTable.table_id);
    }

    render(): ReactNode {
        const {currentTable} = this.props.pokerStore;

        if (!currentTable.player) {
            return <></>;
        }

        return <div className="poker-controller-panel">
            <PokerPanelButtonFold disabled={currentTable.player.waiting}/>
            <PokerPanelButtonCheckCall onCheck={this.check.bind(this)} disabled={currentTable.player.waiting}/>
            <PokerPanelButtonCheckCall onCheck={this.call.bind(this)} disabled={currentTable.player.waiting}/>
            <PokerPanelButtonBetRaise disabled={currentTable.player.waiting}/>
            <PokerPanelButtonPokerGoBet/>
        </div>;
    }
}
