import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    disabled?: boolean
}

export default class PokerPanelButtonBetRaise extends React.Component<Props> {
    render(): ReactNode {
        return <button className="button-adaptive button-adaptive_casino" disabled={this.props.disabled} >
            {I18n.t('poker_bet_raise')}
        </button>;
    }
}
