import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    onCheck: () => void
    disabled?: boolean
}

export default class PokerPanelButtonCheckCall extends React.Component<Props> {
    render(): ReactNode {
        const {onCheck, disabled} = this.props;

        return <button
            className="button-adaptive button-adaptive_casino"
            onClick={onCheck}
            disabled={disabled}
        >
            {I18n.t('poker_check_call')}
        </button>;
    }
}
